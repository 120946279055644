
import BannerSideWrapper from '~/components/elements/BannerSideWrapper';

export default {
  name: 'BannerText',
  components: {BannerSideWrapper },
  props: {
    title: {
      type: String,
      default: '',
    },
    banner: {
      type: Object,
      required: true,
    },
    ratio: {
      type: Object,
      default() {
        return {}
      },
    },
    sizes: {
      type: Object,
      default() {
        return {}
      },
    },
    text: {
      type: String,
      default: '',
    },
    place: {
      type: String,
      default: 'right',
    },
    align: {
      type: String,
      default: 'center',
    },
    round: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: '.4rem',
    },
  },
}
