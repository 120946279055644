
import Banner from '~/components/catalog/banner';

export default {
  name: 'BannerSideWrapper',
  components: { Banner },
  props: {
    title: {
      type: String,
      default: '',
    },
    banner: {
      type: Object,
      required: true,
    },
    ratio: {
      type: Object,
      default() {
        return {}
      },
    },
    sizes: {
      type: Object,
      default() {
        return {}
      },
    },
    place: {
      type: String,
      default: 'right',
    },
    align: {
      type: String,
      default: 'center',
    },
    round: {
      type: Boolean,
      default: false,
    },
    borderRadius: {
      type: String,
      default: '.4rem',
    },
    bodyClass: {
      type: String,
      default: 'dynamic-html',
    },
  },
  computed: {
    textPlace() {
      return `banner-text_place-${this.place}`
    },
    textAlign() {
      return `banner-text_align-${this.align}`
    },
    sliderStyle() {
      return {
        '--banner-ratio-pc': this.ratio.pc,
        '--banner-ratio-tablet': this.ratio.tablet,
        '--banner-ratio-mobile': this.ratio.mobile,
        '--banner-width-pc': `${this.sizes.pc.width}px`,
        '--banner-width-tablet': `${this.sizes.tablet.width}px`,
        '--banner-width-mobile': `${this.sizes.mobile.width}px`,

      }
    },
  },
}
